import logo from './logo.svg';
import './App.css';
import Gains from './gains/GainsScreen';
import WorkoutSelection from './gains/WorkoutSelection';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { getAnalytics } from "firebase/analytics";
import analytics from './firebase';


function App() {
  return (
    <WorkoutSelection />
  );
}

export default App;
