const questions = [
    {
      question: "What is your fitness goal?",
      options: [, "Build muscle", "Increase endurance", "Improve flexibility"]
    },
    {
      question: "Do you have any specific areas you want to focus on?",
      options: [ "Chest", "Core", "Full body"]
    },
    {
      question: "What is your current fitness level?",
      options: ["Beginner", "Intermediate", "Advanced"]
    },
    {
      question: "Do you have access to a gym or workout equipment?",
      options: ["Gym equipment", "No equipment", ]
    },
    {
      question: "How long do you want the workout to last?",
      options: ["15 minutes", "30 minutes"]
    }
  ];
  
  export default questions;