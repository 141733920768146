


const GainsScreen = () => {


    return (
        <div className="hero bg-base-200 min-h-screen">
        <div className="hero-content flex-col lg:flex-row">
            <img
            src="https://img.daisyui.com/images/stock/photo-1635805737707-575885ab0820.webp"
            className="max-w-sm rounded-lg shadow-2xl" />
            <div>
            <h1 className="text-5xl font-bold">Gains made simple</h1>
            <p className="py-6">
               Create a workoutplan completely tailored to your needs. Following a program can be hard, but we make it easy for you.
               Dont worry aboout what you are going to train next week. Just crate a simple plan for today.
            </p>
            <button className="btn btn-primary">Get Started</button>
            </div>
        </div>
        </div>
    )
}


export default GainsScreen;