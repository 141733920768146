import React from 'react';

const Resultat = ({ resultat, setIsSubmitted }) => {
    return (
              
                <div className="card card-compact bg-base-100 shadow-xl mx-auto p-6 w-full flex-1 max-h-screen overflow-y-auto">
                    <h1 className="text-2xl font-bold text-center  mb-4">{[resultat['name']]}</h1>

                    <p className="text-m text-gray-700 mb-4">{resultat["description"]}</p>

                    <div className='overflow-x-auto'>
                        <table className="table">
                        <thead>
                            <tr>
                                <th>Exercise</th>
                                <th>Sets</th>
                                <th>Reps</th>
                                <th>Rest</th>
                            </tr>
                            </thead>
                        {resultat.exercises.map((exercise, index) => (                
                            <tbody key={index}>
                            <tr>
                                {/* <td>{index + 1}</td> */}
                                <td>{exercise.exerciseName}</td>
                                <td>{exercise.sets}</td>
                                <td>{exercise.reps}</td>
                                <td>{exercise.rest}</td>
                            </tr>
                            </tbody>
                    ))}

                   </table>
                        </div>

                    <div className="form-control mt-6">
                        <button onClick={() => setIsSubmitted(false)} className="btn btn-primary">Make a new plan</button>
                    </div>
                </div>
    );
};

export default Resultat;