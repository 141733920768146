import React from 'react';

const PremiumCard = () => {
  const features = [
    "Unlimited access",
    "Choose your own answer",
    "Faster results",
    "Higher quality results",
  ];

  const features2 = [
    ".............",
    ".............",
    ".............",
    ".............",
  ]

  return (
    <div className="card card-compact bg-base-100 shadow-xl shadow-xl flex-1">
      <div className="card-body">
        <h2 className="card-title">More Features coming!</h2>
        {/* <p className='description'>Get access to all the features with one time payment</p> */}
        <ul className="list-disc list-inside">
          {features2.map((feature, index) => (
            <li key={index}>{feature}</li>
          ))}
        </ul>
        {/* <p className="text-3xl mt-6">$9.99/lifetime</p>
        <button className="btn btn-primary w-full mt-6">Get Premium</button> */}
      </div>
    </div>
  );
};

export default PremiumCard;