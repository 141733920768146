
async function sendRequest(goal, bodypart, level, equipment, duration) {
    const url = 'https://get-workout-x37nv3uhba-uc.a.run.app'; // Replace with your Firebase function URL

    const requestData = {
        "goal": goal,
        "bodypart": bodypart,
        "level": level,
        "equipment": equipment,
        "duration": duration
    };

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();
        console.log('Response:', responseData);
        return responseData;

    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

export default sendRequest;