import React from 'react';
import questions from './components/Questions';
import Resultat from './Resultat';
import sendRequest from './network/SendRequest';
import Optioncard from './components/OptionCard';
import Background from './components/Backround';
import Loading from './components/Loading';

const WorkoutSelection = () => {
  const [questionNumber, setQuestionNumber] = React.useState(0);

  // Ensure questionNumber is within the valid range
  const currentQuestion = questions[questionNumber] || questions[0];

  const [answers, setAnswers] = React.useState([]);

  const [isSubmitted, setIsSubmitted] = React.useState(false);
  
  const [resultat, setResultat] = React.useState({});

  const [isLoading, setIsLoading] = React.useState(false);

  const handleNext = async ({ option }) => {
    console.log(option);
    answers.push(option);
    console.log(answers);
  
    if (answers.length == questions.length) {
      setIsLoading(true);
      var r = await sendRequest(answers[0], answers[1], answers[2], answers[3], answers[4]);
      
      // Parse r as JSON if it's a string
      if (typeof r === 'string') {
        r = JSON.parse(r);
      }
      
      setResultat(r);
      console.log(r);
      setIsSubmitted(true);
      setIsLoading(false);
      setAnswers([]);
      setQuestionNumber(0);
    }
  
    // Ensure questionNumber is within the valid range
    if (questionNumber < questions.length - 1) {
      setQuestionNumber(questionNumber + 1);
    }
  };
  
  if (isLoading) {
    return (
      <Background component={<Loading setIsLoading={setIsLoading} />} />
    );
  }
  
  if (isSubmitted) {
    return (
      <Background component={<Resultat resultat={resultat} setIsSubmitted={setIsSubmitted} />} />
    );
  }
  
  return (
    <Background component={<Optioncard currentQuestion={currentQuestion} handleNext={handleNext} />} />
  );
}

export default WorkoutSelection;