import React, { useState } from 'react';

const OptionCard = ({ currentQuestion, handleNext }) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleEnterClick = () => {
    handleNext({ option: inputValue });
    setInputValue(''); // Clear the input after submission
  };

  return (
    <div className="card card-compact bg-base-100 shadow-xl mx-auto p-6 w-full lg:w-[500px] lg:h-[400px] flex-2">
      <div className="card-body">
        <h2 className="card-title">{currentQuestion.question}</h2>
        <p>Choose what fits you the best</p>
        <div className="card-actions justify-end mx-auto flex w-full">
          {currentQuestion.options.map((option, index) => (
            <button onClick={() => handleNext({ option })} key={index} className="btn btn-primary flex-1">
              {option}
            </button>
          ))}
        </div>
        <div className="join mt-10 w-full">
          <input
            className="input input-bordered join-item w-full"
            placeholder="Choose yourself"
            maxLength={50}
            value={inputValue}
            onChange={handleInputChange}
          />
          <button onClick={handleEnterClick} className="btn btn-primary join-item rounded-r-full">
            Enter
          </button>
        </div>
      </div>
    </div>
  );
};

export default OptionCard;