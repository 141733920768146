import PremiumCard from "./PremiumCard";

const Background = ({ component }) => {
  return (
    <div
      className="relative min-h-screen flex items-center justify-center bg-cover bg-center bg-no-repeat"
      style={{
        backgroundImage: 'url("gym.jpg")',
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="hero-overlay bg-opacity-60 absolute inset-0"></div>
      <div className="hero-content flex-col lg:flex-row gap-10 mx-auto">
        {/* Image on the side */}
        <img
          className="lg:w-1/6 shadow-lg rounded-lg flex-1"
          src="2.png"
          alt="restaurant"
        />
        {/* Component in the center */}
        {component}
        <PremiumCard />
      </div>
    </div>
  );
};

export default Background;