import React, { useState, useEffect } from 'react';

const Loading = ({ setIsLoading }) => {

  return (
    <div>
        <div className="card bg-base-100 w-full max-w-sm shrink-0 shadow-2xl p-6">
        <span className="loading loading-dots loading-lg"></span>

        </div>
    </div>
  );
}

export default Loading;