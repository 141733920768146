// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC-wP2P8GrnhoHjA54H1JEi_ZlYD-hvCyo",
  authDomain: "simplegains-18ffe.firebaseapp.com",
  projectId: "simplegains-18ffe",
  storageBucket: "simplegains-18ffe.appspot.com",
  messagingSenderId: "825183351512",
  appId: "1:825183351512:web:e2e0b1da9b879f59f71dad",
  measurementId: "G-XHC3TGBGDV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);